<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Zone</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Create</button>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="division_id">Division</label>
                                            <v-select name="division_id"
                                                v-model="search.division_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= commonObj.divisionList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="district_id">District</label>
                                            <v-select name="district_id"
                                                v-model="search.district_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= districtList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="thana_id">Thana</label>
                                            <v-select name="thana_id"
                                                v-model="search.thana_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= thanaList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="name">Name</label>
                                            <input type="text" id="name" v-model="search.name" placeholder="Enter Name" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="id">ID</label>
                                            <input type="text" id="id" v-model="search.id" placeholder="Enter ID" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Name</th>
                                                    <th>Hub Name</th>
                                                    <th>Division</th>
                                                    <th>District</th>
                                                    <th>Thana</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Name</th>
                                                    <th>Hub Name</th>
                                                    <th>Division</th>
                                                    <th>District</th>
                                                    <th>Thana</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td>{{ item.id }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td>{{ item.hub ? item.hub.name : '' }}</td>
                                                    <td>{{ item.division ? item.division.name : '' }}</td>
                                                    <td>{{ item.district ? item.district.name : '' }}</td>
                                                    <td>{{ item.thana ? item.thana.name : '' }}</td>
                                                    <td style="text-align:center;">
                                                        <button class="btn btn-success btn-sm mr-1" @click="showZone(item)" title="Details"><i class="fas fa-eye"></i></button>
                                                        <button class="btn btn-warning btn-sm mr-1" @click="editZone(item)" title="Edit"><i class="fas fa-pencil-alt"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <div v-if="createModal" class="modal fade show" style="display:block" tabindex="-1" id="addHubModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Create New Zone</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(createZone)">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="name">Name<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="name" v-model="form.name" class="form-control" placeholder="Name in English">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Division" vid="division_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="name">Division <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="division_id"
                                                        v-model="form.division_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.divisionList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="District" vid="district_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="name">District <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="district_id"
                                                        v-model="form.district_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= districtList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Thana" vid="thana_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="name">Thana <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="thana_id"
                                                        v-model="form.thana_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= thanaList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>   
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="hub_id">Hub <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="hub_id"
                                                        v-model="form.hub_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.hubList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>    
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <ValidationProvider name="Zone Area" vid="zone_areas" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="zone_areas">Zone Area <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="zone_areas"
                                                        multiple
                                                        v-model="form.zone_areas"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= areaList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>                         
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="createZone">Save</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="editModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Edit Zone</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body"> 
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(createZone)">
                                    <input type="hidden" v-model="form_edit.id" />
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="name">Name<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Name in English">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Division" vid="division_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="name">Division <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="division_id"
                                                        v-model="form_edit.division_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.divisionList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="District" vid="district_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="name">District <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="district_id"
                                                        v-model="form_edit.district_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= districtList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Thana" vid="thana_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="name">Thana <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="thana_id"
                                                        v-model="form_edit.thana_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= thanaList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>   
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="hub_id">Hub <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="hub_id"
                                                        v-model="form_edit.hub_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.hubList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>    
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <ValidationProvider name="Zone Area" vid="zone_areas" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="zone_areas">Zone Area <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="zone_areas"
                                                        multiple
                                                        v-model="form_edit.zone_areas"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= areaList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>                         
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="updateZone">Update</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="viewModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Edit Zone</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body"> 
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(createZone)">
                                    <input type="hidden" v-model="form_edit.id" />
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="name">Name<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Name in English" readonly>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Division" vid="division_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="name">Division <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="division_id"
                                                        disabled
                                                        v-model="form_edit.division_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.divisionList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="District" vid="district_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="name">District <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="district_id"
                                                        disabled
                                                        v-model="form_edit.district_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= districtList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Thana" vid="thana_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="name">Thana <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="thana_id"
                                                        disabled
                                                        v-model="form_edit.thana_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= thanaList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>   
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="hub_id">Hub <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="hub_id"
                                                        disabled
                                                        v-model="form_edit.hub_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.hubList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>    
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <ValidationProvider name="Zone Area" vid="zone_areas" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="zone_areas">Zone Area <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="zone_areas"
                                                        disabled
                                                        multiple
                                                        v-model="form_edit.zone_areas"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= areaList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>                         
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="deleteModal" class="modal fade show" style="display:block" tabindex="-1" id="deleteZoneModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyZone">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <!-- /.content -->
    </div>
</template>
<script>
import config from '@/config'
export default {
    name:'Zone',
    data(){
        return {
            loader: false,
            loadingState: false,
            search: {
                division_id : '',
                district_id : '',
                thana_id    : '',
                name: '',
                base_charge: '',
                id: ''
            },
            form: {
                division_id : '',
                district_id : '',
                thana_id : '',
                name : '',
                hub_id : '',
                zone_areas: []
            },
            form_edit: {},
            errors:[],
            createModal:false,
            editModal:false,
            viewModal:false,
            deleteModal:false,
            zone_id:'',
            key:'',
            districtList: [],
            thanaList: [],
            areaList: [],
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.commonObj.perPage,
                slOffset: 1
            }
        }
    },
    created(){
        this.loadData();
    },
    watch: {
        stateReload : function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadData()
            }
        },
        'search.id': function (newVal, oldVal) {
            if (newVal != oldVal) {
               this.loadData()
            }
        },
        'search.name': function (newVal, oldVal) {
            if (newVal != oldVal) {
               this.loadData()
            }
        },
        'form.thana_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.areaList = this.getAreaList(newVal)
            }
        },
        'search.division_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'search.district_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.thanaList = this.getThanaList(newVal)
            }
        },
        'form.division_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'form.district_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.thanaList = this.getThanaList(newVal)
            }
        },
        'form_edit.division_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'form_edit.district_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.thanaList = this.getThanaList(newVal)
            }
        },
        'form_edit.thana_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.areaList = this.getAreaList(newVal)
            }
        }
    },
    computed : {
        stateReload () {
            return this.$store.state.stateReload
        },
        listData () {
            return this.$store.state.list
        },
        commonObj () {
            return this.$store.state.commonObj
        }
    },
    methods:{
        searchData () {
            this.loadData()
        },
        async loadData(){     
            this.loader = true 
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })          
            const response = await config.getData('/zone', params)
            this.loader = false
            if (response.status == 200){
                this.$store.dispatch('setList', response.data.data)
                this.paginationData(response.data)
            } else {
                this.$store.dispatch('setList', [])
            } 
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        },
        showCreateModal(){
            this.createModal = true;
        },
        getAreaList (thanaId) {
            return this.commonObj.areaList.filter(item => item.thana_id == thanaId)
        },
        async createZone(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const response = await config.postData('/zone/store', this.form)
            this.loader = false
            if (response.status == 201) {
                this.$store.dispatch('commonObjLoad', true)
                this.createModal = false
                this.form.area_id = this.form.division_id = this.form.district_id = this.form.thana_id = this.form.base_charge =0
                this.form.name = ''
                this.$toast.success({
                    title: 'Success',
                    message: 'Zone created successfully',
                    color: '#218838'
                }) 
            } else {
                this.$refs.form.setErrors(response.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong',
                    color: '#dc3545'
                })   
            }
            this.$store.dispatch('stateReload', false)
        },
        cancelModal(){
            this.createModal = false;
            this.editModal = false;
            this.deleteModal = false;
            this.viewModal = false;
        },
        editZone(zone){
            zone.zone_areas.map(item => {
                const area = this.commonObj.areaList.find(el => el.id == item.area_id)
                return Object.assign(item, { text: typeof area !== 'undefined' ? area.text : '', id: area.id })
            })
            this.form_edit = zone
            this.editModal = true
        },
        showZone(zone){
            zone.zone_areas.map(item => {
                const area = this.commonObj.areaList.find(el => el.id == item.area_id)
                return Object.assign(item, { text: typeof area !== 'undefined' ? area.text : '', id: area.id })
            })
            this.form_edit = zone
            this.viewModal = true
        },
        async updateZone(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const response = await config.postData('/zone/update', this.form_edit)
            this.loader = false
            if (response.status == 201) {
                this.$store.dispatch('commonObjLoad', true)
                this.editModal = false;            
                this.$toast.success({
                    title: 'Success',
                    message: 'Zone updated successfully',
                    color: '#218838'
                })  
            } else {
                this.$refs.form.setErrors(response.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong',
                    color: '#dc3545'
                })  
            }
            this.$store.dispatch('stateReload', false)
        },
        deleteZone(key, zone_id){
            this.zone_id = zone_id;
            this.key = key;
            this.deleteModal = true;
        },
        async destroyZone(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            await config.deleteData('/zone/destroy/'+this.zone_id)
            this.loader = false           
            this.deleteModal = false
            this.$toast.success({
                title: 'Success',
                message: 'Bank deleted successfully',
                color: '#218838'
            }) 
            this.$store.dispatch('stateReload', false)
        },
        getDistrictList (divisionId) {
            return this.commonObj.districtList.filter(dist => dist.division_id == divisionId)
        },
        getThanaList (districtId) {
            return this.commonObj.thanaList.filter(thana => thana.district_id == districtId)
        }
    }
}
</script>